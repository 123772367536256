import ajax from "./ajax.js";
import Qs from "qs";

let base = process.env.VUE_APP_BASE_URL;

const getAidaUserByPhone = (phone) => {
  return ajax.get(`${base}/api/itedu/v1/aida-user/get-by-phone?phone=${phone}`);
};

const addEdit = (params) => {
  return ajax.post(`${base}/api/itedu/v1/user-own-course/add-edit`, params);
};

const queryByUserId = (userId) => {
  return ajax.get(`${base}/api/itedu/v1/user-own-course/query-by-user-id?userId=${userId}`);
};

const getById = (id) => {
  return ajax.get(`${base}/api/itedu/v1/user-own-course/get?id=${id}`);
};

const deleteById = (id) => {
  return ajax.post(`${base}/api/itedu/v1/user-own-course/delete?id=${id}`);
};

const awaitChain = (promise) => {
  return promise.then((data) => [data, null]).catch((err) => [null, err]);
};

export const userOwnCourseApi = {
  getAidaUserByPhone: getAidaUserByPhone,
  addEdit: addEdit,
  queryByUserId: queryByUserId,
  getById: getById,
  deleteById: deleteById,
  awaitChain: awaitChain,
};
