export const CodeLanguageEnum = {
  PYTHON: 0,
  JAVA: 1,
  JAVASCRIPT: 2,
};

export const PracticeTypeEnum = {
  CHOICE: 0,
  FILL_BLANK: 1,
  CODE_FOLLOW: 2,
  CODE_PRACTICE: 3,
};

export const TeachTypeEnum = {
  RECORDING: 0,
  LIVE: 1,
  PRACTICE: 2,
  FEED: 3,
  CODE_FIRST: 4,
  RECITE: 5,
};
export const MarkdownFileType = {
  COS: 0,
  MARKDOWN: 1,
  HTML: 2,
  VOD: 3,
  PRACTICE: 4,
};

export const UnitType = {
  COS: 0,
  MARKDOWN: 1,
  HTML: 2,
  VOD: 3,
  PRACTICE: 4,
};

export const AuthTypeEnum = {
  BUY: 0,
  REGISTER: 1,
  FREE: 2,
};
export const initialFilepath = [
  {
    children: [
      {
        name: "init.js",
        id: 1,
        path: 1,
        repo: "main",
        type: "file",
        content: "init",
      },
    ],
    name: "init",
    id: 2,
    path: 2,
    repo: "main",
    type: "dir",
    content: "",
  },
];
export const orderStatusEnum = {
  PRE_CREATE_FAIL: -5, //"预创建失败"
  PRE_CREATE: -4, //"预创建", false,
  USER_CANCEL: -3, //"用户主动取消", false,
  EXPIRED: -2, //"已失效", false,
  CANCEL: -1, //"取消订单", true,
  UNPAID: 0, //"未支付", true,
  PAID: 1, // "支付", true;',
  WAIT_GROUP_ON: 2, // 待成团,
  REFUND: 3, // 已退款
};
export const payChannelEnum = {
  none: 0,
  user: 1,
  cms: 2,
  xiaoe: 3,
  free_gift: 4,
  exchange_ticket: 5,
};
