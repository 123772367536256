<template>
  <div>
    <el-form
      :label-position="'left'"
      label-width="80px"
      @submit.prevent="onSubmit"
      style="margin: 20px; width: 60%; min-width: 600px"
    >
      <el-form-item label="courseId">
        <el-input
          :disabled="editorStatus"
          v-model="requestContent.courseId"
          @blur.prevent="blurToQueryCourse()"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input :disabled="editorStatus" v-model="requestContent.userPhone"></el-input>
      </el-form-item>
      <el-form-item label="课程名">
        <el-input
          :disabled="editorStatus"
          v-model="requestContent.courseName"
          placeholder="随便写，以系统的为准"
        ></el-input>
      </el-form-item>

      <el-form-item label="过期时间">
        <el-date-picker
          value-format="timestamp"
          style="width: 100%"
          v-model="requestContent.expireTime"
          type="datetime"
          placeholder="选择日期时间"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" :rows="8" v-model="requestContent.remark"></el-input>
      </el-form-item>
    </el-form>
    <el-form
      :label-position="'left'"
      label-width="80px"
      @submit.prevent="onSubmit"
      style="margin: 20px; width: 60%; min-width: 600px"
    >
      <el-form-item class="bottomBtn">
        <el-button @click="saveContent">保存</el-button>
        <el-button>取消</el-button>
        <!-- <el-button @click="publiceArticle" type="primary">立即发布</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { alertMessage } from "@/util/alertMessageUtil";
import { orderStatusEnum, payChannelEnum } from "@/util/EnumUtil";
import { userOwnCourseApi } from "@/api/userOwnCourseApi";
import { aidaCourseApi } from "@/api/aidaCourseApi";
export default {
  data() {
    return {
      requestStatus: false,
      keywords: "",
      saveStatus: false,
      editorStatus: false,
      requestContent: {
        id: "0",
        courseId: "",
        userPhone: "",
        courseName: "",
        expireTime: new Date().getTime() + 86400 * 1000 * 365, // 一年后
        remark: "",
      },
    };
  },
  methods: {
    saveContent() {
      this.submitOrder();
    },
    submitOrder() {
      this.requestStatus = true;

      let alertType = "warning";
      let alertText = "请完善courseId";
      if (this.requestContent.courseId === "") {
        alertMessage(alertText, alertType);
        return;
      }
      alertText = "请完善手机号";
      if (this.requestContent.userPhone === "" || this.requestContent.userPhone.length < 11) {
        alertMessage(alertText, alertType);
        return;
      }
      alertText = "请完善课程名";
      if (!this.requestContent.courseName) {
        alertMessage(alertText, alertType);
        return;
      }
      if (this.requestStatus) {
        userOwnCourseApi.addEdit(this.requestContent).then((ret) => {
          if (ret.code == 0) {
            alertMessage("保存成功", "success");
            this.requestContent = ret.data;
            this.saveStatus = true;
          } else {
            alertMessage(ret.msg, "warning");
          }
        });
      }
    },
    blurToQueryCourse() {
      aidaCourseApi
        .getCourseById(this.requestContent.courseId)
        .then((ret) => {
          if (ret.code == 0) {
            if (ret.data) {
              this.requestContent.courseName = ret.data.courseName;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          alertMessage(err, "warning");
        });
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.id) {
      const id = this.$route.query.id;
      this.editorStatus = true;
      userOwnCourseApi.getById(id).then((ret) => {
        console.log("---ret.data", ret.data);
        if (ret.code === 0) {
          this.requestContent = ret.data;
        } else {
          alertMessage("获取数据失败，请稍后再试", "warning");
        }
      });
    }
  },
};
</script>

<style></style>
