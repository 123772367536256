import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const saveCourse = (params) => {
  return ajax.post(`${base}/api/itedu/v1/course`, params);
};

const queryCourse = (queryText, start, len) => {
  return ajax.get(`${base}/api/itedu/v1/course/query?queryName=${queryText}&start=${start}&len=${len}`);
};

const deleteCourse = (courseId) => {
  return ajax.post(`${base}/api/itedu/v1/course/delete?courseId=${courseId}`);
};

const copyCourse = (courseId) => {
  return ajax.post(`${base}/api/itedu/v1/course/copy?courseId=${courseId}`);
};

const getCourseById = (courseId) => {
  return ajax.get(`${base}/api/itedu/v1/course/${courseId}`);
};

const getCourseListByIds = (courseIds) => {
  return ajax.get(`${base}/api/itedu/v1/course/get-by-ids?courseIds=${courseIds}`);
};

const getCourseSetById = (id) => {
  return ajax.get(`${base}/api/itedu/v1/course-set/get?id=${id}`);
};

const saveCourseSet = (params) => {
  return ajax.post(`${base}/api/itedu/v1/course-set`, params);
};

const queryCourseSet = (pageNo) => {
  return ajax.get(`${base}/api/itedu/v1/course-set/query?pageNo=${pageNo}`);
};

const deleteCourseSet = (id) => {
  return ajax.post(`${base}/api/itedu/v1/course-set/delete?id=${id}`);
};

export const aidaCourseApi = {
  saveCourse: saveCourse,
  queryCourse: queryCourse,
  deleteCourse: deleteCourse,
  copyCourse: copyCourse,
  getCourseById: getCourseById,
  getCourseListByIds: getCourseListByIds,
  getCourseSetById: getCourseSetById,
  saveCourseSet: saveCourseSet,
  queryCourseSet: queryCourseSet,
  deleteCourseSet: deleteCourseSet,
};
